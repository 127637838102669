<template>
  <main id="edit-benchmetric">
    <div id="nav">
    </div>
    <section id="page-title">
      <row>
        <div class="page-title">
          <div align="left" class="back">
            <router-link to="/editproject">
              <img src="../../src/assets/BackArrow.svg" alt="back-arrow" /> Back
            </router-link>
          </div>
            <div class="container-header-section">
            <div class="titleSection">
              <EditableInput
                v-model="Title"
              />
              <p class="info2">The Dashboard title reflects the name of the data column you chose on<br /> the Ist drop down below. Double click on it, to update it.</p>
            </div>
            <div class="save-dashboard-container">
              <div @click="opencancelDashboardCreationModal" class="btn cancel-button">
                <p class="text-cancel-dashboard"><b>&times;</b> Cancel</p>
              </div>

              <!-- Save dashboard (green button) -->
              <save-dashboard-button :saveDashboard="saveDashboard" :isFirstDropdownSelected="isFirstDropdownSelected" />
            </div>
          </div>
        </div>
      </row>
    </section>

    <!-- three-dropdowns -->
    <div class="create-impact-container">
      <div v-if="isLoadingFirstSelect">
        <PreLoader :showBackArrow="false" />
      </div>
      <HeaderSelectsEditBenchmetric
        v-else
        :isFirstDropdownSelected="isFirstDropdownSelected"
        :statusOptions="statusOptions"
        :fundsOptions="fundsOptions"
        :demographicsOptions="demographicsFiltered"
        :setActive="setActive"
        :pivotOrigin="pivotOrigin"
        :selectedFund="fundSelected"
        @pivot-origin-changed="updatePivotOrigin"
        @fund-origin-changed="updateFundOrigin"
        @firstLabelSelect-changed="updateFirstSelect"
        :statusValue="statusValue"
        @secondLabelSelect-changed="updateSecondSelect"
        :fundValue="fundValue"
        @thirdLabelSelect-changed="updateThirdSelect"
        :demoValue="demoValue"
        :isEditingMode="isEditingMode"
      />
    </div>
    

    <div v-if="!isLoadingCalcu">
      <section class="chart-title-area">
        <row class="chart-title">
          <column>
            <h2 class="chart-sub-title">{{ subTitleChart }}</h2>
            <p class="subTitleChart">This title reflects the selections made on the drop-downs of Parameter 1,<br />
              Parameter 2 and Parameter(s) above.</p>
          </column>
          <div class="chart-summary" >
            <div class="total-tenants">
              <span>
                <h1>{{ totalTenants }}</h1>
                <input ref="unitMeasurementRef" class="tenantsValue" v-model="unitMeasurement" />
              </span>
              <h3 class="employment-status-title">employment status - all</h3>
              <div class="small-content">
                <p class="chart-title">Total number of your selections on<br /> the 3 drop-downs above.</p>
              </div>
            </div>
            <div class="growth-rate">
              <div class="total-tenants">
                <span>
                  <h1 class="color-purple">± 103</h1>
                  <input ref="numberDifferenceUnitMeasurement" class="tenantsValue" v-model="numberDifferenceUnitMeasurement" />
                </span>
              </div>

              <div class="d-flex align-items-center">
                <input ref="inputGrowth" class="growthValue" v-model="growthRatetext" />
              </div>
              <div class="small-content">
                <p>Number difference between the<br />time-period(s) on the drop-down below.</p>
              </div>
            </div>

            <div class="growth-rate">
              <h1 class="color-purple">± 4%</h1>
              <div class="d-flex align-items-center">
                <input ref="dropdownTimePeriodPercentageDifference" class="percentage-difference" v-model="dropdownTimePeriodPercentageDifference" />
              </div>
              <div class="small-content">
                <p>Percentage difference between the<br /> time-period(s) on the drop-down below.</p>
              </div>
            </div>
          </div>
        </row>
      </section>
      <section class="display-years">
        <div class="display-years-container">
          <div class="d-flex">
          <div class="data-display-heading">
            <h2>
              Showing data from:
            </h2>
          </div>
          <v-select :options="yearsFiltered" ref="inputYear" v-model="displayingDataUpTo" label="dateRange"
            :searchable="false" :clearable="false" class="project-select year-select option-any-dropdown">
            <template v-slot:option="{ dateRange, enabled, index }">
              <div v-on:click="setActiveOptionYears(index)">
                <div>
                  {{ dateRange }}
                  <button class="close">
                    <div class="icon-container">
                      <span class="select-years-icons">
                        {{ enabled !== false ? `${'&times;'}` : `${'&plus;'}` }}
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </template>
            <span slot="no-options">
              <h3 class="no-options">
                No more available options
              </h3>
            </span>
          </v-select>
        </div>

          <div class="dropdown-description-container">
            <p class="dropdown-description">The graph and table below present a visual showcase of what your own dahsboard <br /> will look like, once you complete its creation on this page.</p>
          </div>
        </div>
        <p class="data-time-period-description">This drop-down reflects the time-periods of your data in the CSVs.</p>
      </section>
      <section id="chart-area">
        <row :gutter="12" class="chart-main">
          <column :lg="8.76" class="line-chart-area">
            <project-chart :showGroupChartData="showGroupChartData" :linechartShow="linechartShow"
              :stackedChartShow="stackedChartShow" :ChartOptions="options" :yAxesValue="yAxes" :chartData="chartData"
              :stackedBarchartOption="stackedBarchartOption" :stackedBarChartData="stackedBarChartData" :isCreatingView="true" @y-axes-value-changed="updateYAxesValue"/>
            <div class="pivot-x-container">
             <span class="data-column-selector">For your X axis you can select any data<br /> column(as loaded from your CSV).</span> 
              <div class="year-select-combobox">
                 <v-select placeholder="Select one" @input="selectOptionDataColumn" :options="demosFilteredFalseStatus"
                  label="name" v-model="pivotX" class="project-select option-any-dropdown">
                </v-select>
              </div>
            </div>
          </column>
          <column :lg="3.24" class="summary-area" v-if="viewMode === 'All' ||
            (selectedStatus === null && stackedChartShow === false) ||
            (linechartShow === true && viewMode === 'Fund')
            ">
            <BarCheckbox :status="status" :itemArr="checkedItems" @check="checkOptions($event)"
              :linechartShow="linechartShow" :summaryBoxData="summaryBoxData" :barChartData="barChartData"
              :barchartOption="barchartOption" />
          </column>
        </row>

        <div class="data-display-container">
          <div>
            <p class="data-display-description">The table shows the numerical values of your<br /> selections in the graph above.</p>
          </div>

          <div class="">
            <p class="data-display-description">These are 3 calculation examples we offer. Please edit the <br /> column names to indicate the calculations you wish to see here.<br />
              We will be happy to generate them for your table.</p>
          </div>
        </div>

        <TableEmployment :status="tableStatus.active" :tableData="tableData" :checkedItems="checkedItems"
          :headerTitles="headerTitles" v-if="linechartShow === true && viewMode !== 'Fund'" :isDataDummy="true" />
        <TableEmployment :status="tableStatus.active" :tableData="tableDataByStatus" :checkedItems="checkedItems"
          :headerTitles="headerTitles" v-if="(stackedChartShow === true && viewMode === 'Status') ||
            (linechartShow === true && viewMode === 'Fund')
            " />
      </section>

    </div>

    <div v-if="editBenchMetricPreloader">
      <PreLoader :showBackArrow="false" />
    </div>

    <footer class="footer-button-save">
      <div @click="opencancelDashboardCreationModal" class="btn cancel-button">
        <p class="text-cancel-dashboard"><b>&times;</b> Cancel</p>
      </div>

      <save-dashboard-button :saveDashboard="saveDashboard" />
    </footer>

    <div class="container-pop-up-card">
      <PopUpCardModal v-for="(modal, index) in modals" :key="index" ref="modalAndPopUpCardRef" :titleCard="modal.title"
        :textMsg="modal.text" :isOk="modal.isOk" :isDelete="modal.isDelete" :deleteFileById="deleteDashboardById" />
    </div>

    <PopUpCardModal 
      ref="modalError" 
      :titleCard="'Information'"
      :textMsg="modalMessage"
      :isOk="true"
    />

    <b-modal
      class="modal-loading-process"
      ref="modal-loading-process"
      id="modal-loading-process"
      size="sm"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <div class="loading-spinner-container">
        <loading-spinner />
        <label class="feedback-spinner-text">
          Generating data...
        </label>
      </div>
    </b-modal>

    <b-modal class="opaque-gradient"
      ref="modal-loading-save" id="modal-loading-save" size="sm" hide-footer centered no-close-on-backdrop
      no-close-on-esc>
      <div class="loading-spinner-container">
        <loading-spinner />
        <label class="feedback-spinner-text">
          Saving dashboard...
        </label>
      </div>
    </b-modal>
  </main>
</template>

<script>
import ProjectChart from "../components/ProjectChart.vue";
import BarCheckbox from '../components/Chart/Checkboxes/BarCheckbox.vue';
import { setYearSelectBox } from '../data/data-provider.js'
import { api } from "../api/stagingApi";
import  { api_b } from "../api/backgroundStagingApi.js";
import { store } from "../store/store";
import LoadingSpinner from '../components/LoadingSpinner.vue'
import PopUpCardModal from "../components/shared/PopUpCardModal.vue";
import SaveDashboardButton from "../components/EditBenchMetric/SaveDashboardButton.vue";
import PreLoader from "../components/Chart/PreLoader.vue";
import { BModal } from 'bootstrap-vue';
import vSelect from "vue-select";

import { changepivotOrigin, deleteImpactById, getCurrentYears, getImpactHeaders, saveObjective, updateDataPage } from "../services/impact";
import {
  barchartOption,
  options,
  stackedBarchartOption,
} from '../helpers/chart/chartOptions';
import { loadImpact } from "../helpers/loadImpact";
import { updateColorsInView } from "../helpers/projectDetails/colors/updateColors";
import { handleAllCaseDashboard } from "../helpers/projectDetails/casesDashboards/handleAllCase";
import { handleStatusCaseDashboard } from "../helpers/projectDetails/casesDashboards/handleStatusCase";
import { handleFundsCaseDashboard } from "../helpers/projectDetails/casesDashboards/handleFundCase";
import { handleDemographicsCaseDashboard } from "../helpers/projectDetails/casesDashboards/handleDemographicsCase";
import { handleAllCase, handleDemoCase, handleFundCase, handleStatusCase } from "../helpers/projectDetails/updateConditionalRendering/conditionalRenderHelpers";
import TableEmployment from "../components/TableEmployment.vue";
import HeaderSelectsEditBenchmetric from '../components/EditBenchMetric/HeaderSelectsEditBenchmetric.vue';
import EditableInput from "../components/EditBenchMetric/EditableInput.vue";

export default {
  components: {
    ProjectChart,
    TableEmployment,
    BarCheckbox,
    LoadingSpinner,
    PopUpCardModal,
    SaveDashboardButton,
    HeaderSelectsEditBenchmetric,
    PreLoader,
    EditableInput,
    BModal,
    vSelect,
},
  data() {
    return {
      isEditMode: false,
      isLoadingFirstSelect: false,
      shouldExecuteCacheCharge: false,
      isLoadingCalcu: true,
      chartData: {},
      barChartData: {},
      tableData: [],
      summaryBoxData: [],
      demographicOptions: [],
      currentDates: [],
      pivotX: null,
      stackedChartShow: false,
      showGroupChartData: false,
      linechartShow: true,
      viewMode: 'All',
      Title: 'Dashboard Title',
      impactId: "",
      growthRatetext: "last 12 months",
      dropdownTimePeriodPercentageDifference: "last 12 months",
      unitMeasurement: "people",
      xAxes: "",
      yAxes: "",
      headerTitles: {
        column1: "Length of Stay",
        column10: "Total people",
        column11: "Positive move on",
        column12: "Move on in avg months",
      },
      column1: "Length of stay",
      selectedStatus: "Show all",
      statusValue: 'Parameter',
      fundValue: 'Parameter',
      demoValue: 'Parameter(s)',
      yearOptions: [],
      demographics: [],
      status: "",
      customSelectList: [],
      yearsSelectList: [],
      checkedItems: [],
      totalTenants: 0,
      displayingDataUpTo: [],
      stackedBarChartData: {},
      isFirstSave: false,
      modals: [
        {
          title: 'Year required',
          text: 'At least one year is required.',
          isOk: true,
        },
        {
          title: 'Save dashboard',
          text: "Remember to save the dashboard before exiting",
          isOk: true,
        },
        {
          title: 'Attention!',
          text: `It appears that you're trying to save the dashboard without selecting an option. Please choose an option from the status dropdown menu before saving.`,
          isOk: true,
        },
        {
          title: 'Cancel creation',
          text: 'Once you confirm the cancellation here, the dashboard will not be created, and all previously selected options as well.',
          isDelete: true,
        },
      ],
      tableStatus: "demo",
      statusOptions: [], // headers
      fundsOptions: [], // headers
      pivotOrigin: null,
      modalMessage: '',
      idcompany: null,
      idproject: null,
      fundSelected: null,
      numberDifferenceUnitMeasurement: "people",
      editBenchMetricPreloader: true,
      isFirstDropdownSelected: false,
      isEditingMode: null,
      selectedCompany: null,
      selectedProject: null,
    }
  },
  async beforeRouteLeave(to, from, next) {
     if (!this.pivotOrigin) {
      next();
    } else {
      if(to.params.createDashboard) return next();

      if(this.isCreationMode) {
        const data = await this.deleteDashboardById(to.name);
        if(!data.error) {
          next();
          return;
        }
      }
      next();
    }
  },
  async mounted() {
    this.isCreationMode = this.$route.params.creating;
    this.isEditingMode = this.$route.params.editing;

    if (this.$cookies.isKey('lastSelectedOptionCompany')) {
      const lastSelectedOptionCompany = this.$cookies.get('lastSelectedOptionCompany');
      this.selectedCompany = lastSelectedOptionCompany;
    }
    if (this.$cookies.isKey('lastSelectedOptionProject')) {
      const lastSelectedOptionProject = this.$cookies.get('lastSelectedOptionProject');
      this.selectedProject = lastSelectedOptionProject;
    }

    this.impactId = this.$route?.params.id;
    await this.loadImpact();
    window.scrollTo(0, 0);

    if(!this.impactId) {
      // se selecciona el primer dropdown
      this.idcompany = this.$route?.query.company;
      this.idproject = this.$route?.query.project;
      this.getCurrentYearsFunc(this.idproject, this.idcompany);
      this.getStatusOptions();
      return;
    }

    this.isEditMode = true;
    this.isFirstDropdownSelected = true;
    this.yearOptions = setYearSelectBox();
  },
  methods: {
    async deleteDashboardById(toRoute = 'home') {
      try {
        this.loading = true;
        const res_data = await deleteImpactById(this.impactId);
        if(res_data.error === false) {
          this.pivotOrigin = null;
          this.$router.push({ name: toRoute });
          return res_data;
        }  

        // console.log('error delete dashboard');
      } catch (error) {
        this.modalMessage = this.errorReqOcurred;
        // TODO: REMOVE NEXT LINE
        this.$refs.modalInfoMessage.showModal();
      } finally {
        this.loading = false;
      }
    },
      
    async changepivotOrigin(impactId, pivotOrigin) {
      try {
        const { error } = await changepivotOrigin({
          impactId,
          pivotOrigin,
        });
        if(!error) {
          this.Title = pivotOrigin;
          // console.log('The origin pivot has been successfully changed');
        }
      } catch (error) {
        console.error(error.message);
      }
    },
    cancelGoToHome() { 
      this.$router.push({ name: 'home' });
    },
    updateYAxesValue(newValue) {
      this.yAxes = newValue;
    },
    updatePivotOrigin(newValue) {
      this.pivotOrigin = newValue;
      if(this.isEditingMode) return;
      if(!this.impactId) {
       this.createImpactId(); 
       return;
      }
      // TODO: SERVICE update the pivot if one was previously selected
      this.changepivotOrigin(this.impactId, this.pivotOrigin);
    },
    updateFundOrigin(newValue) {
      this.fundSelected = newValue;
    },
    updateFirstSelect(newValue) {
      this.statusValue = newValue;
    },
    updateSecondSelect(newValue) {
      this.fundValue = newValue;
    },
    updateThirdSelect(newValue) {
      this.demoValue = newValue;
    },
    showModal(modalIndex) {
      const modalRef = this.$refs.modalAndPopUpCardRef[modalIndex];
      modalRef.showModal();
    },
    async getCurrentYearsFunc(idproject, idcompany) {
      try {
        const res_data = await getCurrentYears({ idcompany, idproject });
        if (res_data.error === false) {
          this.currentDates = res_data.years.map((item, index) => ({
            ...item,
            index
          }));
        } else {
          this.currentDates = res_data?.message ? [] : [];
        }
      } catch (error) {
        this.currentDates = [];
      }
    },
    async getStatusOptions() {
      try {
        const { data } = await getImpactHeaders({
          idcompany: this.idcompany,
          idproject: this.idproject,
        });
        if(data) {
          this.statusOptions = data.headers;
          this.fundsOptions = data.headers;
        }
      } catch (error) {
        console.error(error.message);
      }
    },
    async loadImpact() {
      await loadImpact.call(this)
    },
    setActive(index) {
      const isActiveDemo = !this.demographics[index].status;
      const demoSelected = this.demographics[index].name;
      this.demographics[index].status = isActiveDemo
      this.selectedDemographicOptions(isActiveDemo, demoSelected);
    },

    setActiveOptionYears(index) {
      const isEnabled = !this.currentDates[index].enabled;
      const selectedDate = this.currentDates[index].dateRange;
      const yearSelected = this.currentDates[index].year;
      const filename = this.currentDates[index].filename;
      this.currentDates[index].enabled = isEnabled;

      this.selectedYearsOptions(selectedDate, yearSelected, isEnabled, index, filename);
    },

    async doYouWishChargeCache(idImpact) {
      try {
        const response = await api_b.post("/files/chargeCache", { idImpact });
        if (response) {
          store.state.cached = response.statusText === "OK";
        }
      } catch (error) {
        console.error("error", error);
      }
    },
    selectedDemographicOptions(isActive, demoSelected) {
      let demographicOptionsChanged = this.demographicOptions;
      demographicOptionsChanged.push({ name: demoSelected, status: isActive });
      let uniqueChars = [...new Map(demographicOptionsChanged.map((demo) => [demo.name, demo])).values()];
      this.demographicOptions = uniqueChars;
    },

    selectedYearsOptions(dateRange, year, isEnabled, index, filename) {
      let yearsOptionsChanged = this.currentDates;

      yearsOptionsChanged.push({
        year,
        dateRange,
        enabled: isEnabled,
        filename,
        index
      });

      let uniqueChars = [...new Map(yearsOptionsChanged.map((demo) => [demo.dateRange, demo])).values()];
      this.currentDates = uniqueChars;
    },


    updateDemoDataPage: async (requestOptions) => {
      return await api.post("/files/updateDemoDataPage", requestOptions);
    },
    updateFundDataPage: async (requestOptions) => {
      return await api.post("/files/updateFundDataPage", requestOptions);
    },
    // Change option in dashboard dropdown
    selectOptionDataColumn(event) {
      this.shouldExecuteCacheCharge = true;
      this.pivotX = event;
    },

    checkOptions(checkStatus) {
      if (!this.checkedItems.includes(checkStatus)) {
        this.checkedItems.push(checkStatus)
      } else {
        this.checkedItems = this.checkedItems.filter((e) => e !== checkStatus);
      }
    },
    async updateData() {
      this.updateConditionalRendering();
      switch (this.viewMode) {
        case "All":
          try {
            handleAllCaseDashboard.call(this, true);
          } catch (error) {
            console.log(error);
          }
          break;
        case "Status":
          try {
            handleStatusCaseDashboard.call(this);
          } catch (error) {
            console.log(error);
          }
          break;
        case "Fund":
          handleFundsCaseDashboard.call(this)
          break;
        case "Demo":
          try {
            handleDemographicsCaseDashboard.call(this);
          } catch (error) {
            console.log(error);
          }
          break;
      }
    },
    updateConditionalRendering() {
      switch (this.viewMode) {
        case "All":
          handleAllCase.call(this);
          break;
        case "Status":
          handleStatusCase.call(this);
          break;
        case "Fund":
          handleFundCase.call(this);
          break;
        case "Demo":
          handleDemoCase.call(this);
          break;
      }
    },
    updateColors(view, colorScheme) {
      updateColorsInView.call(this, view, colorScheme)
    },

    async opencancelDashboardCreationModal() {
      if(this.isEditingMode) {
        this.cancelGoToHome(); 
        return;
      }

      if (!this.pivotOrigin) {
        this.cancelGoToHome();
        return;
      }

      this.showModal(3);
    },

    async createImpactId() {
      if (!this.pivotOrigin) {
        this.showModal(2);
        return;
      }
      const objective = {
        id: null,
        name: this.pivotOrigin,
        fundName: this.fundSelected,
      };
      this.$refs['modal-loading-process'].show();
      const company = this.idcompany;
      const project = this.idproject;
      const Fund =
        objective.fundName != null && objective.fundName != ''
          ? objective.fundName
          : 'Fund';
      this.isLoadingFirstSelect = true;
      const resp = await saveObjective({
        idProcess: project,
        idCompany: company,
        pibotes: [objective.name, Fund],
        piboteCalculo: 'Length of stay In months',
      });

      if (resp.error) {
        this.$refs['modal-loading-process'].hide();
        return;
      }

      this.impactId = resp.idImpact;
      if(this.impactId) {
        this.$refs['modal-loading-process'].hide();
        this.isLoadingFirstSelect = false;
        this.$router.replace({ name: "editbenchmetric", params: { id: this.impactId } });
        this.isFirstDropdownSelected = true;
        this.loadImpact();
      }
    },
    async saveDashboard() {
      try {
        if (!this.impactId) {
          this.modalMessage = 'It is necessary to select a main pivot (first dropdown).';
          this.$refs.modalError.showModal();
          return
        }
        if (!this.pivotX.name) {
          this.modalMessage = 'It is necessary to select an X-axis';
          this.$refs.modalError.showModal();
          return
        }
        if (this.yearsFilteredEnabled.length === 0) {
          this.showModal(0);
          return;
        }

        this.isFirstSave = true;
        this.$refs['modal-loading-save'].show();
        await updateDataPage(this.impactId, {
          Title: this.Title,
          Theme: this.Theme,
          XAxes: this.xAxes,
          YAxes: this.yAxes,
          unitMeasurement: this.unitMeasurement,
          tableColumn1: (this.headerTitles.column1 && this.headerTitles.column1.trim().length > 0 && this.headerTitles.column1 != this.pivotX.name) ? this.headerTitles.column1 : "",
          tableColumn10: (this.headerTitles.column10 && this.headerTitles.column10.trim().length > 0) ? this.headerTitles.column10 : "Total tenancies",
          tableColumn11: (this.headerTitles.column11 && this.headerTitles.column11.trim().length > 0) ? this.headerTitles.column11 : "Positive move on",
          tableColumn12: (this.headerTitles.column12 && this.headerTitles.column12.trim().length > 0) ? this.headerTitles.column12 : "Move on in avg months",
          growthRatetext: this.growthRatetext,
          dropdownTimePeriodPercentageDifference: this.dropdownTimePeriodPercentageDifference,
          numberDifferenceUnitMeasurement: this.numberDifferenceUnitMeasurement,
          select1: this.statusValue,
          select2: this.fundValue,
          select3: this.demoValue,
          pivotX: this.pivotX.name,
          years: this.yearsFilteredEnabled,
          pivotOrigin: this.pivotOrigin,
          Fund: this.fundSelected,
        });

        //se valida 
        if (this.fundSelected && !this.isEditingMode) {
          const requestOptions = {
            idImpact: this.impactId,
            fundsOptions: this.fundSelected,
          };
          await this.updateFundDataPage(requestOptions)
        }

        if (this.demographicOptions.length > 0) {
          const demoOptions = JSON.parse(JSON.stringify(this.demographicOptions));
          const requestOptions = {
            idImpact: this.impactId,
            demoOptions,
          };
          // Change status of demo (dropdown) options
          this.updateDemoDataPage(requestOptions)
        }

        this.$refs['modal-loading-save'].hide();
        if (this.shouldExecuteCacheCharge || !this.isEditingMode) {
          this.doYouWishChargeCache(this.impactId);
        }
        this.$router.push({ name: 'home', params: { createDashboard: true, company: this.selectedCompany, project: this.selectedProject } });
      } catch (error) {
        this.$refs['modal-loading-save'].hide();
        console.log('Error', error);
      }
    },
  }, 
  watch: {
    pivotOrigin(newValue) {
      this.pivotOrigin = newValue;
    },
    Title(newValue) {
      this.Title = newValue;
    },
    checkedItems() {
      this.updateData()
    },
  },
  computed: {
    options() {
      return options;
    },
    stackedBarchartOption() {
      return stackedBarchartOption;
    },
    barchartOption() {
      return barchartOption;
    },
    demographicsFiltered: function () {
      const demographicsFilter = this.demographics.filter((demograph) => !this.customSelectList.includes(demograph))
      return demographicsFilter.map((demoOption) => demoOption);
    },
    yearsFiltered: function () {
      const yearsFilter = this.currentDates.filter((years) => !this.yearsSelectList.includes(years))
      return yearsFilter.map((yearOpt) => yearOpt);
    },
    demosFilteredFalseStatus: function () {
      const demographicsFilter = this.demographics.filter((demograph) => demograph.status === false);
      return demographicsFilter.map((demoOption) => demoOption);
    },
    yearsFilteredEnabled: function () {
      return this.yearsFiltered.filter((demoOption) => demoOption.enabled);
    },
    subTitleChart: function () {
      return `${this.statusValue}${this.selectedStatus ? " - " + this.selectedStatus : ""}, ${this.fundValue ? this.fundValue + "," : ""}  ${this.demoValue ? this.demoValue.replace("e.Fg%3F", "e.g.") : ""}`
    },
  }
}
</script>

<style src="@/assets/styles/views/EditBenchMetric.css"></style>
