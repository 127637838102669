<template>
  <div class="select-indication" @dblclick="startEditing">
    <template v-if="!isEditing">
      <h4 v-if="computedValue" class="underline">{{ computedValue }}</h4>
    </template>
    <template v-else>
      <input
        ref="inputRef"
        class="underline"
        v-model="currentValue"
        @blur="stopEditing"
        :readonly="readonly"
        @change="emitChangeEvent"
      />
    </template>
  </div>
</template>
  
<script>
  export default {
    props: {
      value: String,
      readonly: Boolean,
      updateLabel: Function,
      editableTitle: Boolean,
    },
    data() {
      return {
        currentValue: this.value,
        isEditing: false,
        parameter: 'Parameter',
      };
    },
    methods: {
      startEditing() {
        this.isEditing = true;
        this.$nextTick(() => {
          this.$refs.inputRef.focus();
        });
      },
      stopEditing() {
        this.isEditing = false;
        this.$emit("input", this.currentValue);
      },
      emitChangeEvent(event) {
        const newValue = event.target.value;
        this.currentValue = newValue;
        this.$emit("input", newValue);
        if (this.updateLabel) {
          this.updateLabel(newValue);
        }
      },
    },
    computed: {
      computedValue() {
        return this.currentValue;
      } ,
    },
    watch: {
      value(newValue) {
        this.currentValue = newValue;
      }
    },
  };
  </script>
  