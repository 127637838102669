<script>
import EditableInput from "./EditableInput.vue";
import vSelect from "vue-select";

export default {
  name: 'HeaderSelectsEditBenchmetric',
  components: { EditableInput, vSelect },
  props: {
    isFirstDropdownSelected: {
      type: Boolean,
    },
    statusOptions: {
      type: Array,
    },
    fundsOptions: {
      type: Array,
    },
    demographicsOptions: {
      type: Array,
    },
    setActive: {
      type: Function,
    },
    pivotOrigin: {
      type: String,
    },
    selectedFund: {
      type: String,
    },
    statusValue: {
      type: String,
    },
    fundValue: {
      type: String,
    },
    demoValue: {
      type: String,
    },
    isEditingMode: {
      type: Boolean,
    }
  },
  data() {
    return {
      selectedPivotOrigin: this.pivotOrigin,
      selectedDemographic: null,
      localStatusValue: this.statusValue,
      localSelectedFund: this.selectedFund,
      localFundsLabelValue: this.fundValue,
      localDemographicsValue: this.demoValue,
      badgeText: 'Please select the first dropdown',
    }
  },
  watch: {
    isEditingMode(newValue) {
      this.isEditingMode = newValue;
    },
    selectedFund(newValue) {
      this.localSelectedFund = newValue;
    },
    pivotOrigin(newVal) {
      this.selectedPivotOrigin = newVal;
    },
    statusValue(newVal) {
      this.localStatusValue = newVal;
    },
    fundValue(newVal) {
      this.localFundsLabelValue = newVal;
    },
    demoValue(newVal) {
      this.localDemographicsValue = newVal;
    },
  },
  computed: {
    filteredStatusOptions() {
      if (this.localSelectedFund) {
        return this.statusOptions.filter((option) => option !== this.localSelectedFund);
      }
      return this.statusOptions;
    },
    filteredFundOptions() {
      if (this.selectedPivotOrigin) {
        return this.fundsOptions.filter((option) => option !== this.selectedPivotOrigin);
      }
      return this.fundsOptions;
    },
    filteredDemographicOptions() {
      if (this.selectedPivotOrigin && this.localSelectedFund) {
        return this.demographicsOptions.filter((option) => {
          return option.name !== this.selectedPivotOrigin && option.name !== this.localSelectedFund;
        });
      } else if (this.selectedPivotOrigin) {
        return this.demographicsOptions.filter((option) => option.name !== this.selectedPivotOrigin);
      } else if (this.localSelectedFund) {
        return this.demographicsOptions.filter((option) => option.name !== this.localSelectedFund);
      }
      return this.demographicsOptions;
    }
  },
  methods: {
    onPivotOriginChange(newValue) {
      this.selectedPivotOrigin = newValue;
      this.$emit('pivot-origin-changed', newValue);
    },
    onFundOriginChange(newValue) {
      this.localSelectedFund = newValue;
      this.$emit('fund-origin-changed', newValue);
    },
    updateFirstLabelSelect(newValue) {
      this.$emit('firstLabelSelect-changed', newValue);
    },
    updateSecondLabelSelect(newValue) {
      this.$emit('secondLabelSelect-changed', newValue);
    },
    updateThirdLabelSelect(newValue) {
      this.$emit('thirdLabelSelect-changed', newValue);
    },
  }
};
</script>

<template>
  <div class="three-selects-and-info-container">
    <div class="select-within-container">
      <div class="edit-benchmetric-data-box">
        <div class="dropdown-title">
          <EditableInput
            v-model="localStatusValue"
            :updateLabel="updateFirstLabelSelect"
          />
        </div>
        <v-select 
          @input="onPivotOriginChange" 
          v-model="selectedPivotOrigin"
          placeholder="Select one"
          :options="filteredStatusOptions" 
          class="project-select option-any-dropdown" 
          :searchable="true"
          :disabled="isEditingMode"
        >
        </v-select>
      </div>
      <p>
        <span v-if="isEditingMode" class="badge-on-dropdown">Feature coming</span>
        <span class="step-number-1">Step 1:</span>
        Please select a data column (as loaded from your CSV) to define what this dashboard measures. Double click on Parameter to edit the wording.
      </p>
    </div>

    <div class="select-within-container">
      <div class="edit-benchmetric-data-box">
        <div class="dropdown-title">
            <EditableInput
              v-model="localFundsLabelValue"
              :updateLabel="updateSecondLabelSelect"
            />
          </div>


        
        <!-- <v-select @input="onFundOriginChange" v-model="localSelectedFund" placeholder="Select one" :options="filteredFundOptions"
          class="project-select option-any-dropdown" :searchable="false" :disabled="!isFirstDropdownSelected"></v-select> -->
        
        <!-- Disable second dropdown -->
        <v-select @input="onFundOriginChange" v-model="localSelectedFund" placeholder="Select one" :options="filteredFundOptions"
          class="project-select option-any-dropdown" :searchable="false" :disabled="!isFirstDropdownSelected || isEditingMode"></v-select>

      </div>
      <p>
        <span class="step-number-2">Step 2:</span>
          Please select a data column to define your 2nd parameter. Double click on Parameter to define what this drop-down presents.
        <span v-if="!isFirstDropdownSelected" class="badge-on-dropdown">{{ badgeText }}</span>
        <!-- Disable second dropdown  -->
        <span v-if="isEditingMode" class="badge-on-dropdown">Feature coming</span>
      </p>
    </div>
    <div class="select-within-container">
      <div class="edit-benchmetric-data-box">
        <div class="dropdown-title">
          <EditableInput
              v-model="localDemographicsValue"
              :updateLabel="updateThirdLabelSelect"
            />
        </div>

        <v-select v-model="selectedDemographic" placeholder="Show all" :options="filteredDemographicOptions"
          class="project-select option-any-dropdown" :searchable="false" label="name" :disabled="!isFirstDropdownSelected">
          <template v-slot:option="opt">

            <div class="opts-dropdown-demo-container" v-on:click="setActive(opt.order)">
              {{ opt.name }}
              <button class="close">
                <div class="icon-container">
                  <span>
                    {{ opt.status !== false ? `${'&times;'}` : `${'&plus;'}` }}
                  </span>
                </div>
              </button>
            </div>
          </template>
          <!-- If there are no demographics to show -->
          <span slot="no-options">
            <h3>
              No more available options
            </h3>
          </span>
        </v-select>
      </div>
      <p class="long-text">
        <span class="step-number-3">Step 3:</span> Please select multiple data columns using (+) to add them and
            (x) to remove them. Double click on Parameter(s) to edit the wording.
        <span v-if="!isFirstDropdownSelected" class="badge-on-dropdown">{{ badgeText }}</span>
      </p>
    </div>
  </div>
</template>

<style src="../../assets/styles/components/editbenchmetric/HeaderSelectsEditBenchmetric.css"></style>
