import axios from "axios";
import router from "../router";
import { store } from "../store/store";
import config from "../config.js"

const api_b = axios.create({
  baseURL: `${config.API_URL_BACKGROUND}/`,
});

api_b.interceptors.request.use(
  async (config) => {
    const access_token = localStorage.getItem("token");
    if (access_token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${access_token}`,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api_b.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest.url === `/user/refresh`
    ) {
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      //console.log("TOKEN EXPIRED");
      originalRequest._retry = true;
      const new_token = await refresh();
      if (new_token && new_token.token) {
        localStorage.setItem("token", new_token.token);
        originalRequest.headers = {
          ...originalRequest.headers,
          Authorization: `Bearer ${new_token.token}`,
        };
      } else {
        //console.log("ERROR GETTING NEW TOKEN: ", new_token);
        store.dispatch("logoutSession");
        if (router?.currentRoute?.name != "login") {
          router.push({ name: "login", params: { expired: true } });
        }
        return Promise.reject(new_token);
      }
      return new Promise((resolve) => {
        resolve(api_b(originalRequest));
      });
    }
    return Promise.reject(error);
  }
);

const refresh = async function () {
  try {
    const refresh_token = localStorage.getItem("refresh_token");
    const res = await api_b.post(`/user/refresh`, { refresh_token });
    let tokenRefresh = res.data;
    return tokenRefresh;
  } catch (err) {
    return err;
  }
};

export { api_b };
