import { getAllHeadersOptions, getImpact, getImpactHeaders } from '../services/impact';

export async function loadImpact() {
  try {
    if (this.preloader) {
      return;
    }
    if(!this.impactId) {
      this.updateData(); 
      return;
    }
    const res_data = await getImpact(this.impactId);
    if (res_data.error === false) {
      const lastDatapage = res_data.impact;
      this.isFirstSave = lastDatapage.first_save;
      this.pivotOrigin = lastDatapage.pivotOrigin;
      this.fundSelected = lastDatapage.Fund ?? 'Fund';
      // console.log(lastDatapage);
      this.pivotX = {
        active: false,
        name: lastDatapage.pivotX
          ? lastDatapage.pivotX
          : 'Length of stay In months',
        order: 0,
      };

      const idImpact = this.impactId;
      const idCompany = lastDatapage.companyId;
      const idProcess = lastDatapage.idProject;
      //this.impactData( lastDatapage.ImpactId )
      this.fundPlaceholder = lastDatapage.fundPlaceholder;
      this.unitMeasurement = lastDatapage.unitMeasurement;
      this.headerTitles.column1 =
        lastDatapage.tableColumn1 ||
        (lastDatapage.pivotX
          ? lastDatapage.pivotX
          : 'Length of stay In months');
      this.headerTitles.column10 =
        lastDatapage.tableColumn10 &&
        lastDatapage.tableColumn10.trim().length > 0
          ? lastDatapage.tableColumn10
          : 'Total tenancies';
      this.headerTitles.column11 =
        lastDatapage.tableColumn11 &&
        lastDatapage.tableColumn11.trim().length > 0
          ? lastDatapage.tableColumn11
          : 'Positive move on';
      this.headerTitles.column12 =
        lastDatapage.tableColumn12 &&
        lastDatapage.tableColumn12.trim().length > 0
          ? lastDatapage.tableColumn12
          : 'Move on in avg months';

      this.growthRatetext = lastDatapage.growthRatetext;
      this.numberDifferenceUnitMeasurement = lastDatapage.numberDifferenceUnitMeasurement; 
      this.dropdownTimePeriodPercentageDifference = lastDatapage.dropdownTimePeriodPercentageDifference; 
      this.xAxes = lastDatapage.XAxes;
      this.yAxes = lastDatapage.YAxes;

      this.Title = lastDatapage.Title
        ? lastDatapage.Title
        : lastDatapage.pivotOrigin;
      this.selectedName = lastDatapage.pivotOrigin
        ? lastDatapage.pivotOrigin
        : lastDatapage.Title;
      this.DataStatus = lastDatapage.Status;
     
      /*** headers options (status and funds) */
      const headersOptions = await getImpactHeaders({
        idcompany: idCompany,
        idproject: idProcess,
      });

      this.statusOptions = headersOptions.data.headers;
      this.fundsOptions = headersOptions.data.headers;
      this.statusOptions.unshift({ label: 'Show all', code: null });

      this.currentDates.map((item, index) => (item.index = index));

      // demographics options
      const response = await getAllHeadersOptions({
        idCompany,
        idProcess,
        idImpact,
      });
      const allHeadersOpts = response.data.headers;
      allHeadersOpts.map((item, index) => (item.order = index));
      this.demographics = allHeadersOpts;

      if (allHeadersOpts.length > 0) {
        const existsLengthOfStay = allHeadersOpts.findIndex(
          (item) => item.name == lastDatapage.pivotX
        );
        if (existsLengthOfStay == -1) {
          this.pivotX = allHeadersOpts.find((item) => item.status == false);
        }
      }

      this.demoFB = [...this.demographics];

      this.Theme = lastDatapage.Theme;
      this.statusValue = lastDatapage.select1 || 'status';
      this.fundValue = lastDatapage.select2 || 'fund';
      this.demoValue = lastDatapage.select3 || 'demographic';
      this.displayingDataUpTo = lastDatapage.displayingDataUpTo[0].date;

      this.updateData();
      this.getCurrentYearsFunc(idProcess, idCompany);
      this.preloader = false;
      this.editBenchMetricPreloader = false;
      return res_data.impact;
    } else {
      if (res_data?.message) {
        this.updateData();
        console.log(res_data?.message);
      } else {
        console.log('Error on getting impact');
      }
    }
  } catch (error) {
    console.log('Error get home');
  } finally {
    this.preloader = false;
    this.editBenchMetricPreloader = false;
  }
}
